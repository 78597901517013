import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService {
  private controller = 'arquivo';
  private controllerProfessor = 'professor'
  public baseUrl = environment.apiUrl;
  public namespaceProject = environment.namespaceProject;

  constructor(private httpService: HttpClient) { }

  headerFileOptions(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`) ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`) : '';

    return {
      headers: new HttpHeaders({
        Authorization: TOKEN,
      }),
      responseType: 'blob',
      observe: 'response',
    };
  }

  download(id: string): Observable<any> {
    return this.httpService.get(`${this.baseUrl}${this.controller}/${id}`,
      this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  downloadProfessor(id: string): Observable<any> {
    return this.httpService.get(`${this.baseUrl}${this.controller}/${id}/${this.controllerProfessor}`,
      this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  downloadRelatorioAuditoria(params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}${this.controller}/relatorio/auditoria${filters}`,
      this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }
  downloadRelatorioProfissionalComPendencias(params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}relatorio/profissional-com-pendencias${filters}`,
      this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  downloadRelatorioEmailComPendencias(params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}relatorio/email-profissional-com-pendencias${filters}`,
      this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }
}
