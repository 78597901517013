
export class AutocompleteDto {
 public id: string;
 public descricao: string;
 public nome?: string;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.nome = object.nome;
      this.descricao = object.descricao;
    }
  }
}
