import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  CdkDragDrop,
  CdkDragMove,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { SweetalertCustom } from "../../utils/sweetalert-custom";

const speed = 10;
const idDragProfessoresAptos: string = 'professores-aptos';
const msgCargaHorariaExcedida: string = `A carga horária para este professor já está completamente preenchida ou irá exceder com a lotação atual.
                                         <br>Você está ciente desta ação e deseja continuar?`

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent implements OnInit {
  @Input() title: string[] = ['', ''];
  @Input() list1: any[];
  @Input() list2: any[];
  @Input() propertyToUpdate: string; // atributo do objeto a ser atualizado
  @Input() optionsOfProperty: any[2]; // opções que o atributo pode ter lista 1 e lista 2
  @Input() childTemplate: TemplateRef<any>;
  @Input() disabled: boolean = false;
  @Input() permiteMultiplaLotacao: boolean;
  @Input() cargaHorariaComponente: number;

  @ViewChild('scrollEl') scrollEl:ElementRef<HTMLElement>;

  private animationFrame: number | undefined;

  constructor() {}

  ngOnInit() {
    if(this.professorAlocadoIsNullOrEmpty){
      this.list2 = [];
    }
  }

  get professoresDisponiveisIsEmpty(){
    return this.list1?.length === 0;
  }

  get professorAlocadoIsNullOrEmpty(){
    return this.list2?.length === 0 || this.list2 == null;
  }

  async drop(event: CdkDragDrop<string[]>) {
    if(!this.disabled){
      if (event.previousContainer === event.container) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );

      } else {
        if(this.lotacaoMultiplaNaoPermitida(event)){
          SweetalertCustom.newShowAlertConfirm('warning', 'Atenção!', 'Só é possível lotar um(a) professor(a).');
          return;
        }

        if(this.CargaHorariaProfessorIraExceder(event)){

          const responseModal = await SweetalertCustom.showAlertConfirmAndCancel('ATENÇÃO!','info', msgCargaHorariaExcedida);

          if (responseModal.isDismissed) {
            return;
          }
          
        }

        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        this.updateList();
      }
    }
  }

  updateList() {
    if (this.propertyToUpdate && this.optionsOfProperty) {
      this.list1.forEach(x => x[this.propertyToUpdate] = this.optionsOfProperty[0]);
      this.list2.forEach(x => x[this.propertyToUpdate] = this.optionsOfProperty[1]);
    }
  }

  private lotacaoMultiplaNaoPermitida(event:any): boolean {
    return this.list2.length > 0 
      && (event.container.data === this.list2)
       && !this.permiteMultiplaLotacao;
  }

  private CargaHorariaProfessorIraExceder(event: any): boolean {

    if (event.container.id === idDragProfessoresAptos) {
      return false;
    }

    const lotacaoProfessor = event.previousContainer.data[event.previousIndex];
    const cargaHorariaDisponivel = lotacaoProfessor.cargaHorariaTotal - lotacaoProfessor.cargaHorariaPreenchida;
    const cargaHorariaDisponvelPosLotacao = cargaHorariaDisponivel - this.cargaHorariaComponente;

    // quando o professor já tiver excedido a carga dele, a cargaHorariaDisponivel será negativa.
    if (cargaHorariaDisponvelPosLotacao < 0) {
      return true;
    }

    return false;
     
  }

  private scroll($event: CdkDragMove) {
    const { y } = $event.pointerPosition;
    const baseEl = this.scrollEl.nativeElement;
    const box = baseEl.getBoundingClientRect();
    const scrollTop = baseEl.scrollTop;
    const top = box.top + - y ;
    if (top > 0 && scrollTop !== 0) {
        const newScroll = scrollTop - speed * Math.exp(top / 50);
        baseEl.scrollTop = newScroll;
        this.animationFrame = requestAnimationFrame(() => this.scroll($event));
        return;
    }

    const bottom = y - box.bottom ;
    if (bottom > 0 && scrollTop < box.bottom) {
        const newScroll = scrollTop + speed * Math.exp(bottom / 50);
        baseEl.scrollTop = newScroll;
        this.animationFrame = requestAnimationFrame(() => this.scroll($event));
    }
}  
}
