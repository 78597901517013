import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SimNaoEnum } from '@core/enums/sim-nao.enum';



@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() options = new SimNaoEnum().getAll();
  @Input() name = '';
  @Input() label = '';
  @Input() id = '';
  @Input() form: FormGroup;
  @Output() onChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  get idName() {
    return this.id || this.name;
  }

}
