import { ValidateMessages } from 'src/app/core/interfaces/validate-messages';

export class ValidationMessages implements ValidateMessages {
  /**
   * Mensagens de validação
   */
  static msg() {
    return {
      login: {
        required: 'Login é obrigatório.',
        cpfInvalid: 'CPF é inválido.'
      },

      password: {
        required: 'Senha é obrigatória.',
      },

      senhaAtual: {
        required: 'A Senha Atual é obrigatória.'
      },

      novaSenha: {
        required: 'Senha é obrigatória.',
        passwordNotMatch: 'Senhas não se correspondem.',
        passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
      },

      novaSenhaRepeat: {
        required: 'Confirmar Senha é obrigatória.',
        passwordNotMatch: 'Senhas não se correspondem.',
        passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
      },

      name: {
        required: 'Nome é obrigatório.',
      },

      nome: {
        required: 'Nome é obrigatório.',
        maxLength: 'É permitido apenas 80 caracteres.'
      },

      sobrenome: {
        required: 'Sobrenome é obrigatório.',
      },

      cpf: {
        required: 'CPF é obrigatório.',
        cpfInvalid: 'CPF inválido.',
      },

      descricao: {
        required: 'Descrição é obrigatória.',
        maxLength: 'É permitido apenas 200 caracteres.'
      },

      sigla: {
        required: 'Sigla é obrigatória.',
        maxLength: 'É permitido apenas 5 caracteres.'
      },

      email: {
        required: 'E-mail é obrigatório.',
        emailInvalid: 'E-mail é inválido.'
      },

      sobreNome: {
        required: 'Sobrenome é obrigatório.',
      },

      telefone: {
        required: 'Telefone é obrigatório.',
        phoneInvalid: 'Telefone é inválido.'
      },

      perfilId: {
        required: 'Perfil é obrigatório.',
      },

      perfils: {
        required: 'Perfil é obrigatório.',
      },

      municipioId: {
        required: 'Município é obrigatório.',
      },

      estadoId: {
        required: 'Estado é obrigatório.',
      },

      departamentoId: {
        required: 'Departamento é obrigatório.',
      },

      codigo: {
        required: 'Código é obrigatório.',
        maxLength: 'É permitido apenas 9 caracteres.'
      },

      codigoInep: {
        required: 'O Código INEP é obrigatório.',
        maxLength: 'É permitido apenas 5 caracteres.',
      },

      codigoUtb: {
        required: 'O Código UTB é obrigatório.',
        maxLength: 'É permitido apenas 5 caracteres.',
      },

      areaCursoProfissionalId: {
        required: 'Área é obrigatório.',
      },

      subareaId: {
        required: 'Subárea é obrigatório.',
      },

      link: {
        required: 'Link é obrigatório.',
      },

      anexo: {
        required: 'Anexo é obrigatório.',
      },

      ferramentas: {
        required: 'Ferramentas é obrigatório.',
      },

      tipoVeiculo: {
        required: 'Tipo de veiculo é obrigatório.',
      },

      restricaoId: {
        required: 'Restrição é obrigatória.'
      },

      tipoServicoId: {
        required: 'Tipo de Serviço é obrigatório.',
      },

      dataInicio: {
        required: 'A data de início é obrigatória.',
      },

      dataFim: {
        required: 'A data de fim é obrigatória.',
      },

      linkUrl: {
        required: 'O link é obrigatório.',
      },

      camposAdicionais: {
        required: 'Campos adicionais é obrigatório.',
      },
      nomeQuestionario: {
        required: 'O nome do questionário é obrigatório.',
      },
      nomeSecao: {
        required: 'Nome da seção é obrigatório.',
        maxLength: 'É permitido apenas 80 caracteres.'
      },
      posicaoSecao: {
        required: 'A posição  da seção é obrigatória.'
      },
      gerenciaRegionalId: {
        required: 'A gerência regional é obrigatória.'
      },
      perfilAlvoQuestionarioId: {
        required: 'O perfil alvo do questionário é obrigatório.'
      },
      periodicidade: {
        required: 'A periodicidade é obrigatória.'
      },
      intrucoes: {
        required: 'As instruções são obrigatórias.',
        maxLength: 'É permitido apenas 200 caracteres.'
      },
      eixoComponenteCurricularId: {
        required: 'Eixo do componente curricular é obrigatório.'
      },

      serieId: {
        required: 'Série é obrigatório.'
      },

      bimestre: {
        required: 'Bimestre é obrigatório.'
      },

      ano: {
        required: 'Ano é obrigatório.'
      },

      componenteCurricularId: {
        required: 'Componente Curricular é obrigatório.'
      },

      areaFormacaoSuperior: {
        required: 'Área de formação superior é obrigatório.'
      },

      tiposEducacaoId: {
        required: 'Tipo de educação é obrigatorio.'
      },

      modeloDiarioClasse: {
        required: 'Modelo diário de classe é obrigatorio.'
      },

      grau: {
        required: 'Grau é obrigatório.'
      },
      dependenciaAdministrativa: {
        required: 'Dependência Administrativa é obrigatória.'
      },

      tipoInstuicao: {
        required: 'Tipo de instituição é obrigatório.'
      },

      diaLetivo: {
        required: 'O campo "É dia letivo?" é obrigatório.'
      },

      opcoesEducacao: {
        required: 'É necessário selecionar ao menos uma opção de educação.'
      },

      areasConhecimentos: {
        required: 'É necessário selecionar ao menos uma área de conhecimento.'
      },

      componentesCurriculares: {
        required: 'É necessário selecionar ao menos um componente curricular.'
      },

      eixo: {
        required: 'O Eixo Tecnológico é obrigatório.',
        maxLength: 'É permitido apenas 80 caracteres.'
      },

      eixoTecnologicoId: {
        required: 'O Eixo Tecnológico é obrigatório.',
      },

      tipoDestinacaoLixo: {
        required: 'O Tipo de Destinação do Lixo é obrigatório.',
        maxLength: 'É permitido apenas 100 caracteres.'
      },

      obrigacoesLeis: {
        required: 'As Observações e Leis são obrigatórias.',
        maxLength: 'É permitido apenas 5000 caracteres.',
      },

      docente: {
        required: 'O Docente é obrigatório.',
      },

      itinerarioFormativoId: {
        required: 'O Itinerário Formativo é obrigatório.',
      },

      direitoAprendizagem: {
        required: 'Os Direitos de Aprendizagem é obrigatório.',
      },
      tipoMaterialId: {
        required: 'O Tipo de material é obrigatório.'
      },
      tipoProgramaId: {
        required: 'O Tipo de programa é obrigatório.'
      },
      referenciaMaterialId: {
        required: 'A Referência é obrigatória.'
      },
      necessidadesEspeciaisId: {
        required: 'A Necessidade especial é obrigatória.'
      },
      tipo: {
        required: 'Tipo é obrigatório.'
      },
      dia: {
        required: 'Dia é obrigatório.'
      },
      mes: {
        required: 'Mês é obrigatório.'
      }
    };
  }

  messages =  {
    login: {
      required: 'Login é obrigatório.',
      cpfInvalid: 'CPF é inválido.'
    },

    password: {
      required: 'Senha é obrigatória.',
    },

    senhaAtual: {
      required: 'A Senha Atual é obrigatória.'
    },

    novaSenha: {
      required: 'Senha é obrigatória.',
      passwordNotMatch: 'Senhas não se correspondem.',
      passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
    },

    novaSenhaRepeat: {
      required: 'Confirmar Senha é obrigatória.',
      passwordNotMatch: 'Senhas não se correspondem.',
      passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
    },

    name: {
      required: 'Nome é obrigatório.',
    },

    nome: {
      required: 'Nome é obrigatório.',
      maxLength: 'É permitido apenas 80 caracteres.'
    },

    sobrenome: {
      required: 'Sobrenome é obrigatório.',
    },

    cpf: {
      required: 'CPF é obrigatório.',
      cpfInvalid: 'CPF inválido.',
    },

    descricao: {
      required: 'Descrição é obrigatória.',
      maxLength: 'É permitido apenas 200 caracteres.'
    },

    sigla: {
      required: 'Sigla é obrigatória.',
      maxLength: 'É permitido apenas 5 caracteres.'
    },

    email: {
      required: 'E-mail é obrigatório.',
      emailInvalid: 'E-mail é inválido.'
    },

    sobreNome: {
      required: 'Sobrenome é obrigatório.',
    },

    telefone: {
      required: 'Telefone é obrigatório.',
      phoneInvalid: 'Telefone é inválido.'
    },

    perfilId: {
      required: 'Perfil é obrigatório.',
    },

    perfils: {
      required: 'Perfil é obrigatório.',
    },

    municipioId: {
      required: 'Município é obrigatório.',
    },

    estadoId: {
      required: 'Estado é obrigatório.',
    },

    departamentoId: {
      required: 'Departamento é obrigatório.',
    },

    codigo: {
      required: 'Código é obrigatório.',
      maxLength: 'É permitido apenas 9 caracteres.'
    },

    codigoInep: {
      required: 'O Código INEP é obrigatório.',
      maxLength: 'É permitido apenas 5 caracteres.',
    },

    codigoUtb: {
      required: 'O Código UTB é obrigatório.',
      maxLength: 'É permitido apenas 5 caracteres.',
    },

    areaCursoProfissionalId: {
      required: 'Área é obrigatório.',
    },

    subareaId: {
      required: 'Subárea é obrigatório.',
    },

    link: {
      required: 'Link é obrigatório.',
    },

    anexo: {
      required: 'Anexo é obrigatório.',
    },

    ferramentas: {
      required: 'Ferramentas é obrigatório.',
    },

    tipoVeiculo: {
      required: 'Tipo de veiculo é obrigatório.',
    },

    restricaoId: {
      required: 'Restrição é obrigatória.'
    },

    tipoServicoId: {
      required: 'Tipo de Serviço é obrigatório.',
    },

    dataInicio: {
      required: 'A data de início é obrigatória.',
    },

    dataFim: {
      required: 'A data de fim é obrigatória.',
    },

    linkUrl: {
      required: 'O link é obrigatório.',
    },

    camposAdicionais: {
      required: 'Campos adicionais é obrigatório.',
    },
    nomeQuestionario: {
      required: 'O nome do questionário é obrigatório.',
    },
    nomeSecao: {
      required: 'Nome da seção é obrigatório.',
      maxLength: 'É permitido apenas 80 caracteres.'
    },
    posicaoSecao: {
      required: 'A posição  da seção é obrigatória.'
    },
    gerenciaRegionalId: {
      required: 'A gerência regional é obrigatória.'
    },
    perfilAlvoQuestionarioId: {
      required: 'O perfil alvo do questionário é obrigatório.'
    },
    periodicidade: {
      required: 'A periodicidade é obrigatória.'
    },
    intrucoes: {
      required: 'As instruções são obrigatórias.',
      maxLength: 'É permitido apenas 200 caracteres.'
    },
    eixoComponenteCurricularId: {
      required: 'Eixo do componente curricular é obrigatório.'
    },

    serieId: {
      required: 'Série é obrigatório.'
    },

    bimestre: {
      required: 'Bimestre é obrigatório.'
    },

    ano: {
      required: 'Ano é obrigatório.'
    },

    componenteCurricularId: {
      required: 'Componente Curricular é obrigatório.'
    },

    areaFormacaoSuperior: {
      required: 'Área de formação superior é obrigatório.'
    },

    tiposEducacaoId: {
      required: 'Tipo de educação é obrigatorio.'
    },

    modeloDiarioClasse: {
      required: 'Modelo diário de classe é obrigatorio.'
    },

    grau: {
      required: 'Grau é obrigatório.'
    },
    dependenciaAdministrativa: {
      required: 'Dependência Administrativa é obrigatória.'
    },

    tipoInstuicao: {
      required: 'Tipo de instituição é obrigatório.'
    },

    diaLetivo: {
      required: 'O campo "É dia letivo?" é obrigatório.'
    },

    opcoesEducacao: {
      required: 'É necessário selecionar ao menos uma opção de educação.'
    },

    areasConhecimentos: {
      required: 'É necessário selecionar ao menos uma área de conhecimento.'
    },

    componentesCurriculares: {
      required: 'É necessário selecionar ao menos um componente curricular.'
    },

    eixo: {
      required: 'O Eixo Tecnológico é obrigatório.',
      maxLength: 'É permitido apenas 80 caracteres.'
    },

    eixoTecnologicoId: {
      required: 'O Eixo Tecnológico é obrigatório.',
    },

    tipoDestinacaoLixo: {
      required: 'O Tipo de Destinação do Lixo é obrigatório.',
      maxLength: 'É permitido apenas 100 caracteres.'
    },

    obrigacoesLeis: {
      required: 'As Observações e Leis são obrigatórias.',
      maxLength: 'É permitido apenas 5000 caracteres.',
    },

    docente: {
      required: 'O Docente é obrigatório.',
    },

    itinerarioFormativoId: {
      required: 'O Itinerário Formativo é obrigatório.',
    },

    direitoAprendizagem: {
      required: 'Os Direitos de Aprendizagem é obrigatório.',
    },
    tipoMaterialId: {
      required: 'O Tipo de material é obrigatório.'
    },
    tipoProgramaId: {
      required: 'O Tipo de programa é obrigatório.'
    },
    referenciaMaterialId: {
      required: 'A Referência é obrigatória.'
    },
    necessidadesEspeciaisId: {
      required: 'A Necessidade especial é obrigatória.'
    }
  };

  getMessages() {
    return this.messages;
  }
}
