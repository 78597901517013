<div [formGroup]="form">
    <div class="custom-file d-none">
      <input #file type="file" class="custom-file-input" [name]="name"
        [accept]="accept" (change)="onSelectFile($event)">
      <label class="custom-file-label" for="anexoContrato">Escolha um arquivo...</label>
    </div>
    <button  class="btn rounded-pill btn-sm btn-primary col-12" [ngClass]="{'btn-md': mdButton, 'btn-sm': !mdButton}"
      (click)="file.click()" *ngIf="!viewAnexo()" [disabled]="!file">Escolha um arquivo para fazer upload
    </button>
    <div class="flex justify-content-center flex-wrap" *ngIf="viewAnexo()">
      <button id="downloadFile" 
        title="Baixar o documento anexado" 
        class="mx-2 btn btn-sm"
        (click)="file.click()">
        <em class="fas fa-paperclip fa-lg"></em>
        {{ form.controls[name].value?.name  || form.controls[name].value?.nomeOriginal || form.controls[name].value?.nome }}
      </button>
      <button *ngIf="file" class="btn btn-outline-primary border-0 rounded-circle" (click)="clear()" title="Remover documento">
        <em class="fas fa-times"></em>
      </button>
      <!-- <button *ngIf="typePage === 'visualizar'" class="btn btn-outline-primary border-0 rounded-circle" (click)="download()"
        title="Baixar o documento anexado">
        <em class="fas fa-download"></em>
      </button> -->
    </div>
  </div>
