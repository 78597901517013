import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Util } from 'src/app/shared/utils/util';

declare var $: any;

@Component({
  selector: 'app-sidebar-auth',
  templateUrl: './sidebar-auth.component.html',
  styleUrls: ['./sidebar-auth.component.scss']
})
export class SidebarAuthComponent implements OnInit, AfterContentChecked {

 public usuarioSession;
 public perfilDescription;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    $(document).ready(() => {
      $('[data-widget="treeview"]').Treeview('init');
      $('.menu-item').click(() => {
        $('.nav-icon').toggleClass('open');
      });
    });
    this.getCurrentUserSession();
  }

  ngAfterContentChecked() {
    this.activeSideBar();
  }

  public activeSideBar() {
    const baseUrl = window.location.origin;
    const url = window.location.href.replace(baseUrl, '');
    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).removeClass('active');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .removeClass('menu-open')
      .prev('a')
      .removeClass('active');

    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).addClass('active');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .addClass('menu-open')
      .prev('a')
      .addClass('active');
  }

  public getCurrentUserSession() {
    this.usuarioSession = Util.getUsuarioSession();
    this.perfilDescription = Util.getperfilDescriptionSession();
  }

  checkRotaInicio() {
    if (Util.isUserGRE()) {
      return '/quadro-funcional'
    } else {
      return '/'
    }
  }

}
