import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Diretiva para permitir apenas letras e números em um elemento de entrada.
 *
 * @Directive
 * @description Essa diretiva impede que o usuário insira caracteres especiais
 * ou acentos em um elemento de entrada, permitindo apenas letras maiúsculas e
 * minúsculas e números. A diretiva também impede que o usuário cole texto que
 * não esteja em conformidade com essas regras.
 *
 * @usageNotes Aplique essa diretiva a um elemento de entrada usando o seletor `appAlphanumericOnly`.
 *
 * @example
 * <input type="text" appAlphanumericOnly>
 */

@Directive({
  selector: '[appAlphanumericOnly]'
})
export class AlphanumericOnlyDirective {

  private regex: RegExp = new RegExp(/^[a-zA-Z0-9]*$/);

  constructor(private el: ElementRef) {}

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    if (!this.regex.test(pastedText)) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab') {
      return;
    }

    const inputValue = this.el.nativeElement.value;
    if (!this.regex.test(inputValue + key)) {
      event.preventDefault();
    }
  }
}
