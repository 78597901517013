<div class="card" [ngClass]="customClass">
  <div #ref class="content col-12 d-flex flex-column justify-content-center">
    <h6> {{data.title}} </h6>
    <h6> {{data.subtitle}} </h6>
    <ng-content content></ng-content>
  </div>
  <div class="d-flex justify-content-center">
    <button [disabled]="disabledButton" type="submit" title="{{labelButton}}"
      (click)="onClick($event.target)"
      class="btn rounded-pill btn-dark mb-3 mr-2 col-md-auto">
      {{labelButton}}
    </button>
  </div>
  <div #line class="line">
    <div class="percentual"> <div> .</div> </div>
  </div>
</div>
