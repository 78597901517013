<div [formGroup]="form">
  <div class="custom-file d-none">
    <input #file [type]="isEdit ? 'text' : 'file'" formControlName="fileControl" class="custom-file-input"
      [accept]="accept" [formControlName]="name" (change)="onSelectFile($event)">
    <label class="custom-file-label" for="anexoContrato">Escolha um arquivo...</label>
  </div>
  <button class="btn btn-primary rounded-pill col-12 col-md-4" [ngClass]="{'btn-md': mdButton, 'btn-sm': !mdButton}" (click)="file.click()"
    *ngIf="form.controls[name].invalid">Anexar arquivo em
    {{accept.slice(1)}}
  </button>
  <div class="row" *ngIf="form.controls[name].valid">
    <a id="downloadFile" title="Baixar o documento anexado" class="mx-2 btn rounded-pill btn-sm"
      (click)="canDownload ? download() : file.click()">
      <i class="fas fa-paperclip fa-lg"></i>
      {{isEdit ? form.controls[name].value?.nomeOriginal : form.controls[name].value?.name}}
    </a>
    <button class="btn rounded-pill btn-outline-primary border-0 rounded-circle" *ngIf="canDelete" (click)="clear()" title="Remover documento">
      <i class="fas fa-times"></i>
    </button>
    <button class="btn rounded-pill btn-outline-primary border-0 rounded-circle" (click)="download()"
      title="Baixar o documento anexado" *ngIf="canDownload">
      <i class="fas fa-download"></i>
    </button>
  </div>
</div>
