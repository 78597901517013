import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ArquivoDto } from '@core/dto/arquivo/arquivo.dto';

import { ArquivoService } from 'src/app/core/services/arquivo.service';

@Component({
  selector: 'app-upload-one-file',
  templateUrl: './upload-one-file.component.html',
  styleUrls: ['./upload-one-file.component.scss'],
})
export class UploadOneFileComponent implements OnInit {
  @Input() accept = '.pdf';
  @Input() form: FormGroup = new FormGroup({});
  @Input() name = '';
  @Input() canDownload = true;
  @Input() canDelete = true;
  @Input() mdButton = false;
  @Input() isEdit = false;
  @Input() isProfessor = false;
  @Output() isEditChanged = new EventEmitter();
  @Output() update = new EventEmitter();

  constructor(private arquivoService: ArquivoService) { }

  public get arquivoForm() {
    return this.form.get(this.name);
  }

  ngOnInit() {
    if (this.arquivoForm.status === 'DISABLED') {
      this.arquivoForm.enable();
    }
  }

  public onSelectFile(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.controls[this.name].patchValue(file, {
      emitModelToViewChange: false,
    });
    this.update.emit(false);
  }

  public clear(): void {
    this.isEdit = false;
    this.form.controls[this.name].patchValue('');
    this.update.emit(this.isEdit);
  }

  public downloadFile(base64: any, fileName: string): void {
    const downlodedFile = new Blob([base64], { type: 'application/pdf' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public downloadFromBack(arquivo: ArquivoDto): void {
    this.arquivoService.download(arquivo.id.toString()).subscribe(
      (res) => {
        this.downloadFile(res.body, arquivo.nomeOriginal);
      },
      (err) => { }
    );
  }

  public downloadProfessorFromBack(arquivo: ArquivoDto): void {
    this.arquivoService.downloadProfessor(arquivo.id.toString()).subscribe(
      (res) => {
        this.downloadFile(res.body, arquivo.nomeOriginal);
      },
      (err) => { }
    );
  }

  public download(): void {
    const arquivo = this.form.controls[this.name].value;
    if (arquivo.nomeOriginal && this.isProfessor) {
      this.downloadProfessorFromBack(arquivo);
      return;
    }
    if (arquivo.nomeOriginal) {
      this.downloadFromBack(arquivo);
      return;
    }
    this.downloadFile(arquivo, arquivo.name);
  }
}
