import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-large-file',
  templateUrl: './upload-large-file.component.html',
  styleUrls: ['./upload-large-file.component.scss']
})
export class UploadLargeFileComponent implements OnInit {
  @Input() form: FormGroup = new FormGroup({});
  @Input() accept: string = '.pdf,.xlsx,.DBF';
  @Input() name = 'file';
  @Input() mdButton = false;
  @Input() endpoint = '';
  @Output() uploaded = new EventEmitter();

  public file;
  private progress: number = 0.0;

  private baseUrl           = environment.apiUrl;
  private namespaceProject  = environment.namespaceProject;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  public onSelectFile(event: Event): void {
    // So seleciona s ainda nao tem arquivo selecionado
    if (this.file) {
      return;
    }

    // Pega o arquivo selecionado
    this.file = (event.target as HTMLInputElement).files[0];
    this.form.controls[this.name].setValue(this.file);
    // this.update.emit({ data: this.file ? true : false, type: MethodEnum.CAD, key: this.name });
  }

  public viewAnexo(): boolean {
    if (this.file || this.form.get(this.name)?.value) {
      return true;
    }
    return false;
  }

  public clear() {
    this.form.controls[this.name].setValue('');
    // this.update.emit({ data: this.isEdit, type: MethodEnum.EDIT, key: this.name });
    this.file = undefined;
  }

  public upload(): boolean {
    if (!this.file) {
      return false;
    }

    const formData = new FormData();
    formData.append('file', this.file);

    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`)
      ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`)
      : '';

    const customHeaders = new HttpHeaders({
      Authorization: TOKEN,
    });

    const uploadRequest = new HttpRequest('POST', `${this.baseUrl}${this.endpoint}`, formData, {
      headers: customHeaders,
      reportProgress: true,

    });

    this.http.request(uploadRequest)
    .pipe(catchError(e => { return of(null); }))
    .subscribe(event => {
      if (event?.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      }
      else if (event?.type === HttpEventType.Response) {
        this.uploaded.emit({
          status: event.status,
          body: event.body?.toString() ?? '',
        });
      }
    });
  }
}
