import { INICIO_INFO_MENU, SUPORTE_AO_SISTEMA_INFO_MENU } from './navbar-infobar-menu.constant';

export interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
  profiles?: string[];
}

export const MENUS_NAVBAR: { infoMenu: string; menus: MenuType[] }[] = [
  {
    infoMenu: SUPORTE_AO_SISTEMA_INFO_MENU,
    menus: [
      {
        url: '/configuracao',
        label: 'Configurações Básicas',
      },
      {
        url: '/outros',
        label: 'Outros',
      },
      {
        url: '/profissional',
        label: 'Profissional',
      },
      {
        url: '/auditoria',
        label: 'Auditoria',
      },
    ],
  },
  {
    infoMenu: INICIO_INFO_MENU,
    menus: [
      {
        url: '/gestao-pedagogica',
        label: 'Gestão Pedagógica',
        profiles: ['administrador']
      },
      {
        url: '/quadro-funcional',
        label: 'Quadro Funcional',
        profiles: ['administrador', 'gre', 'rh']
      },
    ],
  },
  // {
  //   url: '/profissional',
  //   label: 'Profissional',
  // },
  // {
  //   url: '/quadro-funcional',
  //   label: 'Quadro Funcional',
  // },

  // {
  //   url: '/escola',
  //   label: 'Escolas',
  // },
  // {
  //   url: '#',
  //   label: 'Alunos',
  // },
  // {
  //   url: '#',
  //   label: 'Profissionais',
  // },
];
