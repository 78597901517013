import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Util } from 'src/app/shared/utils/util';

@Injectable({
  providedIn: 'root'
})
export class ControlAccessGuard implements CanActivate {


  constructor(
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const usuarioLogado = Util.getUsuarioSession().perfil.descricao;
    if(usuarioLogado){
      let routerUsuariosAcesso = next.data.userAccess
      if(routerUsuariosAcesso){
        const hasAccess = this.userHasAccess(routerUsuariosAcesso, usuarioLogado)

        if(!hasAccess){
          this.router.navigate(['']);
          return false
        }
      }

    }


    return true;
  }
  userHasAccess(userAcessList, userPerfil){
    return userAcessList.some(perfis => {
      return userPerfil.toUpperCase()
        .includes(perfis.toUpperCase())
    })
  }

  isIsFirsAccess(usuario) {
    if (usuario !== null && usuario.cadastroCompleto && usuario.primeiroAcesso) {
      this.router.navigate(['/auth/primeiro-acesso']);
      return false;
    }
  }

}
