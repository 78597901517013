import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBase } from '../../utils/form-base';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ArquivoService } from '@core/services/arquivo.service';
import { MethodEnum } from '@core/enums/method.enum';
import { FileTypeEnum } from '@core/enums/file-type.enum';

@Component({
  selector: 'app-upload-button-custom',
  templateUrl: './upload-button-custom.component.html',
  styleUrls: ['./upload-button-custom.component.scss']
})
export class UploadButtonCustomComponent extends FormBase implements OnChanges {

  @Input() editable: boolean = true;
  @Input() inputName: string = 'file';
  @Input() nameButton: string = 'Escolha um arquivo para fazer upload';
  @Input() showIcon: boolean = false;
  @Input() classIcon: string = 'fa-download';
  @Input() accept = '.pdf';
  @Input() form: FormGroup = new FormGroup({});
  @Input() name = '';
  @Input() canDownload = true;
  @Input() canDelete = true;
  @Input() mdButton = false;
  @Input() isEditFile: boolean;
  @Input() isProfessor = false;
  @Output() isEditChanged = new EventEmitter();
  @Output() update = new EventEmitter();
  public file;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private arquivoService: ArquivoService) {
    super(router, activatedRoute);
    this.choosePageUpdateOrView();
  }

  ngOnChanges(): void {
    if (this.isEditFile) {
      this.inputName = 'text';
    }
  }

  public onSelectFile(event: Event): void {
    if (!this.editable) {
      return;
    }
    this.file = (event.target as HTMLInputElement).files[0];
    this.form.controls[this.name].setValue(this.file);
    this.update.emit({ data: this.file ? true : false, type: MethodEnum.CAD, key: this.name });
  }

  public clear(): void {
    this.isEditFile = false;
    this.form.controls[this.name].setValue('');
    this.update.emit({ data: this.isEditFile, type: MethodEnum.EDIT, key: this.name });
    this.file = undefined;
    this.viewAnexo();
  }

  public downloadFile(base64: any, fileName: string, typeName = 'pdf'): void {
    const downlodedFile = new Blob([base64], { type: new FileTypeEnum().getValue(typeName) as string });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = `${fileName}.${typeName}`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public downloadFromBack(arquivo: any): void {
    this.arquivoService.download(arquivo.id.toString()).subscribe(res => {
      this.downloadFile(res.body, arquivo.nomeOriginal || arquivo.nome, arquivo.extensao);
    }, (err) => { });
  }

  public downloadProfessorFromBack(arquivo: any): void {
    this.arquivoService.downloadProfessor(arquivo.id.toString()).subscribe(res => {
      this.downloadFile(res.body, arquivo.nomeOriginal || arquivo.nome, arquivo.extensao);
    }, (err) => { });
  }

  public download(): void {
    const arquivo = this.form.controls[this.name].value;
    if (arquivo.id && this.isProfessor) {
      this.downloadProfessorFromBack(arquivo);
      return;
    }
    if (arquivo.id) {
      this.downloadFromBack(arquivo);
      return;
    }
    this.downloadFile(arquivo, arquivo.name);
  }

  public viewAnexo(): boolean {
    if (this.form.get(this.name)?.value) {
      return true;
    }
    return false;
  }

  public truncateFileName(fileName: string, maxLength: number = 30): string {
    if (fileName.length > maxLength) {
      return fileName.slice(0, maxLength) + '...';
    }
    return fileName;
  }


}
