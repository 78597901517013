<div class="info-bar">
  <div class="container">
    <div class="row justify-content-end align-items-center">
      <div class="mr-auto">
        <h6 class="info-teste" *ngIf="ambienteQA">
          {{usuarioSession.nome}}, você está no ambiente de HOMOLOGAÇÃO/TREINAMENTO.
        </h6>
      </div>
      <ul class="nav-info">
        <li *ngFor="let menu of menusInfoBar">
          <a
            *ngIf="shouldShowNavbarItem(menu)"
            class="nav-item"
            [title]="menu.label"
            [routerLink]="menu.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: menu.exact }"
          >
          {{ menu.label }}
          </a>
        </li>
        <li>
          <a [routerLink]="['/auth/logout']" class="nav-item active" title="Sair"> Sair </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<nav>
  <div class="container h-100">
    <div class="row h-100">
      <a routerLink="/" class="col-4 logo-container">
        <img src="assets/img/siage_Logo_01.png" alt="" srcset="" class="logo" />
      </a>

      <!-- Menu -->
      <ul class="nav-menu">
        <li *ngFor="let menu of menusNavbar">
          <a
            *ngIf="shouldShowNavbarItem(menu)"
            [title]="menu.label"
            class="nav-item"
            [routerLink]="getMenuUrl(menu)"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: menu.exact }"
          >
            {{ menu.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- <nav class="main-header navbar navbar-expand navbar-custom navbar-dark">
  <ul class="navbar-nav menu-icon">
    <li class="nav-item" style="margin-top: -5px;">
      <a class="nav-link menu-item" data-widget="pushmenu" href="#">
        <div class="nav-icon open">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
  </ul>
  <ul  class="navbar-nav ml-auto">
    <li class="nav-item dropdown ml-2">
      <a class="nav-link btn-link-custom-secundary" [routerLink]="['/auth/logout']" title="sair" data-toggle="dropdown"
        href="#">
        <em class="fas fa-sign-out-alt text-white"></em>
      </a>
    </li>
  </ul>
</nav> -->
