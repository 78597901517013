<div class="bg-white mb-5 py-3" *ngIf="showFilter">
  <div class="container-fluid">
    <div class="d-flex justify-content-between w-100">
      <strong class="pb-2 h5 d-block">Buscar por:</strong>

      <div class="align-self-center d-flex align-items-center">
        <ng-content select="[buttons]"></ng-content>
        <a *ngIf="registerButton" id="add-button" [routerLink]="pathRegister"
          class="btn rounded-pill btn-sm btn-outline-primary" title="Adicionar {{ options.title }}">
          Adicionar
        </a>
      </div>
    </div>

    <ng-content select="[filters]"></ng-content>

    <section class="row">
      <div class="col-12 col-md-12">
        <button type="submit" id="btn-buscar" (click)="submit()" title="Buscar" [disabled]="!canSubmit"
          class="btn rounded-pill btn-primary float-right ml-md-2 mb-2 col-md-auto">
          Buscar
          <!-- <em
            class="fa fa-spinner fa-spin"
            *ngIf="buttonSubmit.buttonSubmited"
          ></em> -->
        </button>
        <button id="btn-limpar" (click)="clearFilter()" id="clear-button" title="Limpar" type="submit"
          class="btn rounded-pill btn-outline-primary float-right ml-md-1 mb-2 col-md-auto">
          Limpar
        </button>
      </div>
    </section>
  </div>
</div>

<div class="container-fluid">
  <div class="card card-red rounded-bottom-custom box-rounded-bottom rounded-0 mt-3">
    <div class="card-body border-top">
      <div class="row">
        <div class="col-12">
          <app-table-custom [items]="items" [itemsPerPage]="10" [paginatorCustom]="paginatorCustom" [page]="page"
            (changePage)="changePage($event)">
            <ng-template appTableHeader>
              <tr>
                <th *ngFor="let column of table.columns; let index = index" [id]="column + '-column'">
                  {{ column.title }}
                  <em *ngIf="column.orderTo" class="fas fa-sort" (click)="orderColumn(column.orderTo)"></em>
                </th>

                <th id="col-3" class="table-columns-acoes" *ngIf="showActions || table.customActions">
                  Ações
                </th>
                <th *ngIf="isCrud" id="col-4">
                  Em uso
                </th>
              </tr>
            </ng-template>
            <ng-template appTableBody let-item let-index>
              <tr (click)="lineClicked(item)">
                <ng-container *ngIf="!table.customColumns">
                  <td *ngFor="let column of table.columns" class="text-{{ column.align || 'left' }}">
                    <textarea *ngIf="column.showInTextarea; else elseTextarea" cols="25"
                      [value]="getValueColumn(item, column)" readonly></textarea>
                    <ng-template #elseTextarea>
                      {{ getValueColumn(item, column) }}
                    </ng-template>
                  </td>
                </ng-container>
                <ng-container *ngIf="table.customColumns">
                  <ng-container *ngTemplateOutlet="
                      customColumns;
                      context: { $implicit: item, index: index }
                    "></ng-container>
                </ng-container>

                <td class="table-columns-acoes" *ngIf="showActions">
                  <button id="btn-visualizar" type="button" class="btn rounded-pill btn-default btn-sm mr-2"
                    title="Visualizar" (click)="view(item)" *ngIf="table.actions.view">
                    <em class="fas fa-search"></em>
                  </button>
                  <button id="btn-editar" type="button" class="btn rounded-pill btn-default btn-sm mr-2" title="Editar"
                    (click)="edit(item)" *ngIf="table.actions.edit">
                    <em class="fas fa-pencil-alt"></em>
                  </button>
                  <button id="btn-excluir" type="button" class="btn rounded-pill btn-default btn-sm" title="Excluir"
                    (click)="delete(item)" *ngIf="table.actions.delete">
                    <em class="fas fa-trash"></em>
                  </button>
                  <button id="btn-inativar" type="button" class="btn rounded-pill btn-default btn-sm mr-2"
                    [title]="item.ativo ? 'Desativar' : 'Ativar'" (click)="inactive(item)"
                    *ngIf="table.actions.inactive">
                      <em
                      class="fas"
                      [ngClass]="definirCorIcone(item)"
                    ></em>
                  </button>
                  <div class="form-check" *ngIf="table.actions.check">
                    <input id="checkbox" class="form-check-input" type="checkbox"
                      (click)="check(item, $event.target.checked)" />
                  </div>
                </td>

                <td *ngIf="isCrud" class="text-center">
                  <button *ngIf="item.tabelasRelacionadas?.length" (click)="exibirTabelasRelacionadas(item)"
                    class="btn rounded-pill btn-default btn-sm" title="Em uso" id="btn-em-uso" type="button">
                    <em class="fas fa-check-circle text-primary">
                    </em>
                  </button>
                </td>

              </tr>
            </ng-template>
            <ng-template appTableEmpty>
              <tr>
                <td [attr.colspan]="totalColspan" class="text-center">
                  Nenhum registro foi encontrado
                </td>
              </tr>
            </ng-template>
          </app-table-custom>
        </div>
      </div>
    </div>
  </div>
</div>
