import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutAuthComponent } from './core/layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './core/layouts/layout-no-auth/layout-no-auth.component';
import { ControlAccessGuard } from '@core/guards/control-access.guard';


export const APP_ROUTES: Routes = [
  {
    path: 'auth',
    component: LayoutNoAuthComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: null },
  },
  {
    path: '',
    component: LayoutAuthComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: null },
    children: [
      {
        path: 'suporte',
        data: { breadcrumb: null, userAccess: ['administrador'] },
        canActivate: [ControlAccessGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/configuracao/configuracao.module').then(
                (m) => m.ConfiguracaoModule
              ),
            data: { breadcrumb: 'Configurações Básicas' },
          },
          {
            path: 'outros',
            loadChildren: () =>
              import('./modules/outros/outros.module').then(
                (m) => m.OutrosModule
              ),
            data: { breadcrumb: 'Outros' },
          },
          {
            path: 'configuracao',
            loadChildren: () =>
              import('./modules/configuracao/configuracao.module').then(
                (m) => m.ConfiguracaoModule
              ),
            data: { breadcrumb: 'Configurações Básicas' },
          },
          {
            path: 'auditoria',
            loadChildren: () =>
              import('./modules/auditoria/auditoria.module').then(
                (m) => m.AuditoriaModule
              ),
            data: { breadcrumb: 'Auditoria' },
          },
          {
            path: 'profissional',
            loadChildren: () =>
              import('./modules/profissonal/profissonal.module').then(
                (m) => m.ProfissonalModule
              ),
            data: { breadcrumb: 'Profissional' },
          },
        ],
      },
      {
        path: '',
        data: { breadcrumb: null },
        children: [
          {
            path: 'gestao-pedagogica',
            loadChildren: () =>
              import(
                './modules/gestao-pedagogica/gestao-pedagogica.module'
              ).then((m) => m.GestaoPedagogicaModule),
            data: { breadcrumb: 'Gestão Pedagógica' },
          },
          {
            path: 'quadro-funcional',
            loadChildren: () =>
              import('./modules/quadro-funcional/quadro-funcional.module').then(
                (m) => m.QuadroFuncionalModule
              ),
            data: { breadcrumb: 'Quadro Funcional'},
          },
          // {
          //   path: 'quadro-funcional',
          //   loadChildren: () =>
          //     import('./modules/quadro-funcional/quadro-funcional.module').then(
          //       (m) => m.QuadroFuncionalModule
          //     ),
          //   data: { breadcrumb: 'Quadro Funcional'},
          // },
          // {
          //   path: 'profissional',
          //   loadChildren: () =>
          //     import('./modules/profissonal/profissonal.module').then(
          //       (m) => m.ProfissonalModule
          //     ),
          //   data: { breadcrumb: null },
          // },
          // {
          //   path: 'escola',
          //   loadChildren: () =>
          //     import('./modules/escola/escola.module').then(
          //       (m) => m.EscolaModule
          //     ),
          //   data: { breadcrumb: null },
          // },
          // {
          //   path: 'auditoria',
          //   loadChildren: () =>
          //     import('./modules/auditoria/auditoria.module').then(
          //       (m) => m.AuditoriaModule
          //     ),
          //   data: { breadcrumb: 'Auditoria'},
          // },
        ],
      },
    ],
  },
];
