<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <ng-content content></ng-content>
      <footer class="row">
        <div class="col-12 col-md-12">
          <ng-content buttons></ng-content>
          <div class="mb-1">
            <small *ngIf="isEdit"
              >Última atualização em
              {{ ultimaAtualizacao }}</small
            >
            <small *ngIf="isEdit && lastUser"> pelo usuário 
              {{ ultimoUsuario }}</small>
          </div>
          <button
            *ngIf="typePage != 'visualizar' && !isView"
            type="submit"
            (click)="submit()"
            title="Salvar"
            [disabled]="!canSubmit"
            class="btn rounded-pill btn-primary float-right mb-2 col-md-auto"
          >
            {{ pageId ? "Atualizar" : "Salvar" }} 
            <em
              class="fa fa-spinner fa-spin"
              *ngIf="buttonSubmit.buttonSubmited"
            ></em>
          </button>
          <a [routerLink]="pathBack">
            <button
              *ngIf="!isView"
              type="submit"
              title="Cancelar"
              class="btn rounded-pill btn-outline-primary float-left mb-2 col-md-auto"
            >
              &nbsp; Cancelar &nbsp;
            </button>
            <a [routerLink]="pathBack">
              <button
                *ngIf="isView"
                type="submit"
                title="Voltar"
                class="btn rounded-pill btn-outline-primary float-left mb-2 col-md-auto"
              >
                &nbsp; Voltar &nbsp;
              </button>
            </a>
          </a>
        </div>
      </footer>
    </div>
  </div>
</div>
