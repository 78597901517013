import { EnumBase } from '@core/models/enum-base.model';


export class FileTypeEnum extends EnumBase {
    constructor() {
        super([
            { value: 'image/jpeg', name: 'jpg' },
            { value: 'application/pdf', name: 'pdf' }
        ]);
    }
}
