
export class AutocompleteEnumDto {
 public value: string;
 public name?: string;

  constructor(object?: any) {
    if (object) {
      this.value  = object.value;
      this.name   = object.name;
    }
  }
}
