<div class="container">
    <div class="row">
        <ng-container *ngFor="let rota of sortedRoutes">
            <div class="col-sm-6 col-md-4" *ngIf="rota.path && rota.data.breadcrumb">
                <div class="card card-navigator p-3">
                    <p class="card-info-title">
                        {{ rota.data.title || rota.data.breadcrumb }}
                    </p>
                    <div>
                        <a [routerLink]="rota.path"
                            class="btn rounded-pill btn-primary col-auto d-inline float-right">Acessar</a>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>