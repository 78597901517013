<div cdkDropListGroup>
  <div class="container p-0">
    <div
      id="professores-aptos"
      #scrollEl cdk-scrollable
      cdkDropList
      [cdkDropListData]="list1"
      class="list drag-scroll-area"
      (cdkDropListDropped)="drop($event)">
      <button type="button" class="list-group-item active" aria-current="true">
        {{title[0]}}
      </button>
      <div class="box" *ngFor="let item of list1" cdkDrag>
        <ng-container [ngTemplateOutlet]="childTemplate" [ngTemplateOutletContext]="{ data: item }"></ng-container>
      </div>

      <div class="text-center" *ngIf="professoresDisponiveisIsEmpty">
        <p>Nenhum professor disponível.</p>
      </div>

    </div>
  </div>

  <div class="container p-0">
    <div
      id="professores-lotados"
      #scrollEl cdk-scrollable
      cdkDropList
      [cdkDropListData]="list2"
      class="list drag-scroll-area"
      (cdkDropListDropped)="drop($event)">
      <button type="button" class="list-group-item active" aria-current="true">
        {{title[1]}}
      </button>
      <div class="box" *ngFor="let item of list2" cdkDrag>
        <ng-container [ngTemplateOutlet]="childTemplate" [ngTemplateOutletContext]="{ data: item }"></ng-container>
      </div>

      <div class="text-center" *ngIf="professorAlocadoIsNullOrEmpty">
        <p>Nenhum professor lotado disponível.</p>
      </div>

    </div>
  </div>
</div>
