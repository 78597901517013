<div [formGroup]="form">
    <div class="custom-file d-none">
      <input #file type="file" class="custom-file-input" [name]="name"
        [accept]="accept" (change)="onSelectFile($event)">
      <label class="custom-file-label" for="anexoContrato">Escolha um arquivo...</label>
    </div>
    <button  class="btn rounded-pill btn-sm btn-primary ml-1  col-md-12" [ngClass]="{'btn-md': mdButton, 'btn-sm': !mdButton}"
      (click)="file.click()" *ngIf="!viewAnexo()" [disabled]="!editable"> {{setButtonName(accept)}}
    </button>
    <div class="row ml-1  col-md-12" *ngIf="viewAnexo()">
      <button id="downloadFile" title="Baixar o documento anexado" class="mx-2 btn btn-sm" *ngIf="exibirNomeAnexo"
        (click)="canDownload ? download() : file.click()">
        <em class="fas fa-paperclip fa-lg"></em>
        {{ this.form.controls[name].value?.name  || this.form.controls[name].value?.nomeOriginal || this.form.controls[name].value?.nome }}
      </button>
      <button [disabled]="typePage === 'visualizar'" class="btn rounded-pill btn-outline-danger border-0 rounded-circle" (click)="clear()" title="Remover documento" *ngIf="editable">
        <em class="fas fa-times"></em>
      </button>
      <button [disabled]="typePage === 'visualizar'" class="btn rounded-pill btn-outline-primary border-0 rounded-circle" (click)="download()"
        title="Baixar o documento anexado" *ngIf="canDownload">
        <em class="fas fa-download"></em>
      </button>
    </div>
  </div>
  