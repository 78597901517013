export const ColorsGraph = {
    color_graph_ciano: '#00c8ff',
    color_graph_ciano_light: '#bff1ff',
    color_graph_green: '#8bff4d',
    color_graph_green_light: '#e5ffd8',
    color_graph_pink: '#ff006a',
    color_graph_pink_light: '#ffbfd9',
    color_graph_orange: '#ffcc00',
    color_graph_orange_light: '#fff2bf',
    color_graph_blue_dark: '#022859',
    color_graph_ciano_dark: '#00647f',
}

export const ColorsGraphArray = [
    ColorsGraph.color_graph_ciano,
    ColorsGraph.color_graph_green,
    ColorsGraph.color_graph_pink,
    ColorsGraph.color_graph_orange,
    ColorsGraph.color_graph_blue_dark,
    ColorsGraph.color_graph_ciano_light,
    ColorsGraph.color_graph_ciano_dark,
    ColorsGraph.color_graph_green_light,
    ColorsGraph.color_graph_pink_light,
    ColorsGraph.color_graph_orange_light,
]

export const ColorsPizzaGraphArray = [
    ColorsGraph.color_graph_ciano,
    ColorsGraph.color_graph_ciano_dark,
    ColorsGraph.color_graph_pink,
    ColorsGraph.color_graph_green,
    ColorsGraph.color_graph_orange,
    ColorsGraph.color_graph_blue_dark,
    ColorsGraph.color_graph_ciano_light,
    ColorsGraph.color_graph_green_light,
    ColorsGraph.color_graph_pink_light,
    ColorsGraph.color_graph_orange_light,
]