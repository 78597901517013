export const URL_CONFIG = Object.freeze({
  url_autoComplete: 'sub-area',
  url_autoComplete_areas: 'areas',
  url_autocomplete: 'autocomplete',
  url_autocomplete_ferramentas: 'ferramentas',
  url_autocomplete_tipo_material: 'tipo-material',
  url_autocomplete_referencia_material: 'referencia-material',
  url_perfis: 'perfil',
  url_usuario: 'usuario',
  url_unidade: 'tipoUnidade',
  url_autocomplete_sub_area: 'sub-area',
  url_restricoes: 'restricao',
  url_atividade_aee: 'atividadeAee',
  url_area_atividade_complementar: 'AreaAtividadeComplementar',
  url_atividade_complementar: 'AtividadeComplementar',
  url_municipios: 'municipio',
  url_municipio_estado: 'municipio-by-estado',
  url_distritos: 'distrito',
  url_gerencias: 'GerenciaRegional',
  url_equipamentos: 'Equipamentos',
  url_mobiliarios: 'Mobiliarios',
  url_referencia_material: 'ReferenciaMaterial',
  url_material: 'TipoMaterial',
  url_curso_formacao_especifica: 'CursoFormacaoEspecifica',
  url_manter_material: 'Material',
  url_manter_necessidade: 'ManterNecessidadesEspeciais',
  url_ferramenta: 'Ferramenta',
  url_subarea: 'SubareaAtividadeComplementar',
  url_manuais: 'manual',
  url_destinatario: 'Destinatario',
  url_recurso_necessario: 'RecursoNecessario',
  url_programa: 'Programa',
  url_tipo_programa: 'TipoPrograma',
  url_tipo_colegiado: 'TipoColegiado',
  url_tipo_espaco_fisico: 'TipoEspacoFisico',
  url_meio_transporte: 'MeioTransporte',
  url_tipo_documento: 'TipoDocumento',
  url_novidade: 'Novidade',
  url_servico: 'Servico',
  url_questionario: 'Questionario',
  url_metodologia_ensino: 'MetodologiaEnsino',
  url_series: 'Serie',
  url_componente_curricular_local: 'ComponenteCurricularLocal',
  url_area_cursos_profissionais: 'AreaCursosProfissionais',
  url_etapa: 'Etapa',
  url_cursos_profissionais: 'GestaoCursoProfissional',
  url_modulos_curriculares: 'ModuloCurricular',
  url_cargo: 'Cargo',
  url_cargo_comissionado: 'TipoCargoComissionadoProfissional',
  url_motivo_saida: 'TipoMotivoSaidaProfissional',
  url_secretaria_municipal_ensino: 'SecretariaMunicipalEnsino',
  url_area_formacao_superior: 'AreaFormacaoSuperior',
  url_regime_contratacao: 'RegimeContratacao',
  url_tipos_educacao: 'TiposEducacao',
  url_orgao_emissor: 'OrgaoEmissor',
  url_lingua_indigena: 'LinguaIndigena',
  url_eixo_componente_curricular: 'EixosComponentesCurriculares',
  url_instituicao_ensino_superior: 'InstituicaoEnsinoSuperior',
  url_descricao_componente_curricular: 'DescricaoComponenteCurricular',
  url_conteudo_componente_curricular: 'ConteudoComponenteCurricular',
  url_curso_formacao_superior: 'CursoFormacaoSuperior',
  url_formacao_academica: 'TipoFormacaoProfissional',
  url_evento: 'Evento',
  url_projeto_especial: 'ProjetoEspecial',
  url_area_conhecimento: 'AreaConhecimento',
  url_opcao_educacao: 'OpcaoEducacao',
  url_atendimento: 'Atendimento',
  url_mediacao: 'Mediacao',
  url_estrutura_curricular: 'EstruturaCurricular',
  url_itinerario_formativo: 'ItinerarioFormativo',
  url_modalidade: 'Modalidade',
  url_temporalidade: 'Temporalidade',
  url_turno: 'Turno',
  url_eixo_tecnologico: 'EixoTecnologico',
  url_curso: 'Curso',
  url_orgao_vinculacao_escola: 'OrgaoVinculacaoEscola',
  url_situacao_funcionamento: 'SituacaoFuncionamento',
  url_equipamento_tecnico_administrativo: 'EquipamentoTecnicoAdministrativo',
  url_equipamentos_processo_aprendizagem: 'EquipamentosProcessoAprendizagem',
  url_tipos_acesso_internet: 'TipoAcessoInternet',
  url_esfera_administrativa: 'EsferaAdministrativa',
  url_local_funcionamento: 'LocalFuncionamento',
  url_localizacao_diferenciada: 'LocalizacaoDiferenciada',
  url_equipamentos_acesso_internet: 'EquipamentosAcessoInternet',
  url_fontes_energia_eletrica: 'FontesEnergiaEletrica',
  url_computador_aluno: 'ComputadorAluno',
  url_forma_ocupacao_predio: 'FormaOcupacaoPredio',
  url_tipo_rede: 'TipoRede',
  url_tipo_abastecimento_agua: 'TipoAbastecimentoAgua',
  url_localizacao_zona_escola: 'LocalizacaoZonaEscola',
  url_tipo_esgotamento_sanitario: 'TipoEsgotamentoSanitario',
  url_recurso_acessibilidade: 'RecursoAcessibilidade',
  url_cadastro_escola: 'CadastroEscola',
  url_carga_horaria: 'CargaHoraria',
  url_cep: 'Cep',
  url_dependencia_fisica_existente: 'DependenciaFisicaExistente',
  url_dependencia_administrativa: 'DependenciaAdministrativa',
  url_tipo_tratamento_lixo: 'TipoTratamentoLixo',
  url_tipo_destinacao_lixo: 'TipoDestinacaoLixo',
  url_profissionais_atuantes: 'ProfissionaisAtuantes',
  url_tipo_reserva_vaga: 'TipoReservaVaga',
  url_tipo_profissional: 'TipoProfissional',
  url_instrumento_material_sociocultural: 'InstrumentoMaterialSociocultural',
  url_matriz_curricular_combinacao: 'MatrizCurricularCombinacao',
  url_auditoria: 'Log',
  url_tipo_sala: 'TipoSala',
  url_categoria_sala: 'CategoriaSala',
  url_categoria_avaliacao: 'CategoriaAvaliacao',
  url_importacao_profissionais: 'Importacao',
  url_importacao_email: 'Importacao',
  url_perfil_docente: 'PerfilDocente',
  url_componente_curricular_bncc: 'ComponenteCurricularBncc',
  url_escola: 'escola',
  url_codigo_classificacao_funcional: 'TipoCodigoClassificacaoFuncionalProfissional',
  url_situacao: 'TipoSituacaoFuncionalProfissional',
  url_tipo_aposentadoria: 'TipoAposentadoriaProfissional',
  url_forma_admissao: 'TipoFormaAdmissaoProfissional',
  url_ensino: 'TipoEnsinoProfissional',
  url_instrucao: 'TipoInstrucaoProfissional',
  url_numero_estudantes_por_sala: 'NumeroEstudanteSala',
  url_funcao: 'Funcao',
  url_ano_letivo: 'AnoLetivo',
  url_utb: 'Utb',
  url_feriado: 'Feriado',
  url_genero: 'Genero',
  url_cor_raca: 'CorRaca',
  url_etnia_indigena: 'EtniaIndigena',
  url_restricao_alimentar: 'RestricaoAlimentar',
  url_pais_nacionalidade: 'PaisNacionalidade',
  url_fardamento: 'Fardamento',
  url_tipo_deficiencia: 'TipoDeficiencia',
  url_nacionalidade: 'Nacionalidade',
  url_recurso_avaliacao: 'RecursoAvaliacaoSeab',
  url_produtividade: 'Produtividade',
  url_orgao_controle_evasao: 'OrgaosControleEvasao',
  url_cartorio: 'Cartorio',
  url_metodologia: 'Metodologia',
  url_habilidade: 'Habilidade',
  url_competencias: 'Competencia',
  url_atitude: 'Atitude',
  url_justificativa_inativacao_matricula: 'JustificativaInativacaoMatricula',
  url_exclusao_registro: 'ExclusaoRegistro',
  url_escolarizacao: 'EscolarizacaoOutroEspaco',
  url_tipo_cargo_comissionado: 'TipoCargoComissionado',
});

export const URL_QUADRO_FUNCIONAL = Object.freeze({
  url_gre: 'gre',
  url_espelho: 'espelho',
  url_corpo_diretivo_detalhe: 'corpo-diretivo-detalhe',
  url_demais_profissionais_detalhe: 'demais-profissionais-detalhe',
  url_professor_detalhe: 'professor-detalhe',
  url_professor: 'professor',
  url_alocacao: 'alocacao',
  url_demais_profissionais: 'demais-profissionais',
  url_corpo_diretivo: 'corpo-diretivo',
  url_componente_curricular: 'componente-curricular',
  url_disponivel: 'disponivel',
  url_disponivel_modal: 'disponivel-modal',
  url_professores_modal_lotacao_coordenacao:'escola/alocacao-coordenacao-pedagogica/professor-disponivel',
});
