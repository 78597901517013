import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBase } from "src/app/shared/utils/form-base";
import { GenericListColumn, GenericListOptions } from "../generic-types";
import { CustomColumnsDirective } from "./directives/custom-columns.directive";
import { PaginacaoDto } from "@core/dto/paginacao-dto";
import { SweetalertCustom } from "src/app/shared/utils/sweetalert-custom";


@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss']
})
export class GenericListComponent extends FormBase implements OnInit {

  @ContentChild(CustomColumnsDirective, { static: true, read: TemplateRef }) customColumns;

  @Input() page: PaginacaoDto<any>;
  @Input() canSubmit: boolean = true;
  @Input() options: GenericListOptions<any>;
  @Input() items: any[] = [];
  @Input() registerButton: boolean = true;
  @Input() showFilter: boolean = true;
  @Input() paginatorCustom: boolean = false;
  @Input() isCrud: boolean = true;

  @Input() customEditPath: string;
  @Input() customRegisterPath: string;

  @Output() onChangePage = new EventEmitter<number>();
  @Output() onOrder = new EventEmitter<string>();
  @Output() onFilter = new EventEmitter<boolean>();
  @Output() onInactive = new EventEmitter<string>();
  @Output() onClearFilter = new EventEmitter<boolean>();
  @Output() onDelete = new EventEmitter<string>();
  @Output() onClick = new EventEmitter<string>();
  @Output() onCheck = new EventEmitter<any>();

  constructor(public router: Router, public activedRoute: ActivatedRoute) {
    super(router, activedRoute)
  }

  ngOnInit(): void {
  }

  get table() {
    return this.options?.tableConfig;
  }

  get showActions() {
    return Object.values(this.table.actions).some(can => can)
  }

  get pathRegister() {
    return this.router.url + '/cadastrar/';
  }

  get pathEdit() {
    return this.customEditPath || this.router.url + '/editar/';
  }

  get pathView() {
    return this.customEditPath || this.router.url + '/visualizar/';
  }

  get totalColspan() {
    return this.table?.columns.length + (this.showActions ? 1 : 0) + (this.table.customActions ? 1 : 0)
  }

  getValueColumn(item: any, column: GenericListColumn<any>) {
    const value = item[column.attribute];
    if (column.formatter)
      return column.formatter(item);
    if (typeof value == 'boolean')
      return item[column.attribute] ? 'Sim' : 'Não';
    return item[column.attribute];

  }

  submit() {
    this.onFilter.emit();
  }

  clearFilter() {
    this.onClearFilter.emit(true)
  }

  inactive(item) {
    this.onInactive.emit(item)
  }

  changePage(page) {
    this.onChangePage.emit(page)
  }

  orderColumn(column) {
    this.onOrder.emit(column)
  }

  view(item) {
    this.router.navigateByUrl(this.pathView + item.id)
  }

  edit(item) {
    this.router.navigateByUrl(this.pathEdit + item.id)
  }

  delete(item) {
    this.onDelete.emit(item)
  }

  lineClicked(item) {
    this.onClick.emit(item);
  }

  check(item, checked) {
    this.onCheck.emit({ item, checked });
  }

  public exibirTabelasRelacionadas(item: any) {
    SweetalertCustom.ShowAlertConfirmExclusaoByRelacionamento(
      item.tabelasRelacionadas
    );
  }

  public definirCorIcone(item: any) {
    return item.ativo ? 'fa-toggle-on' : 'fa-toggle-on  off-custom';
  }
}
