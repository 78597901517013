import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ListBoxType } from '../../types/list-box.type';
import { ListBoxDto } from '@core/dto/list-box-dto';

@Component({
  selector: 'app-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss']
})
export class ListBoxComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() name: string = '';
  @Input() customClass: any = '';
  @Input() canEdit: boolean = true;
  @Input() links: boolean = false;

  @Output() onDelete = new EventEmitter<any>();

  public listItems: ListBoxType = [];
  public formHasIds: boolean;

  constructor() { }

  ngOnInit() {
    this.validList();
    this.form.get(this.name).valueChanges.subscribe(value => {
      this.validList(value);
    })
  }

  validList(value = this.form.get(this.name).value) {
    if (value.length > 0) {
      this.updateList();
    } else {
      this.listItems = [];
    }
  }

  public setListIds() {
    if (this.listItems.length < 1) {
      return;
    }

    this.listItems.forEach(
      (item, index) => {
        if (item.id) {
          this.formHasIds = true;
        }
        item.id = item.id ? item.id : index.toString();
        return item;
      }
    )
  }

  public removeItem(itemToRemove: { nome: string; id?: string; }) {
    this.listItems = this.listItems
      .filter(
        item => item.id != itemToRemove.id
      );
    let realItens: any = this.listItems;
    if (!this.formHasIds) {
      realItens = this.getItens();
    }

    this.form.get(this.name)?.setValue(realItens);
    this.onDelete.emit();
  }

  public getItens() {
    return this.listItems.map(
      item => item.nome
    );
  }

  formatUrl(url: string): string {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  }

  updateList() {
    this.listItems = JSON.parse(JSON.stringify(this.form.get(this.name).value));
    this.listItems = this.listItems.filter(item => {
      if(item){
        return item;
      }
    } )
    this.listItems = this.listItems.map(item => new ListBoxDto(item));
    this.setListIds();
  }

}