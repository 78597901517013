<ng-container [formGroup]="form">
  <p-autoComplete
    [disabled]="disabled"
    placeholder="Selecione"
    [id]="idName"
    class="custom-auto-complete"
    [formControlName]="name"
    [delay]="900"
    dataKey="id"
    [suggestions]="optionsForAutocomplete"
    [forceSelection]="true"
    (completeMethod)="getOptions($event)"
    [field]="fieldName"
    [dropdown]="true"
    [emptyMessage]="'Nenhum resultado encontrado'"
    minLength="1"
    (onSelect)="onChange.emit($event)"
    (onClear)="onClear.emit()"
    [ngClass]="customClass"
    (focusout)="onFocusOut.emit($event)"
  >
  </p-autoComplete>
</ng-container>
