import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxPaginationModule } from 'ngx-pagination';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { CardComponent } from './card/card.component';
import { AlertCustomComponent } from './components/alert-custom/alert-custom.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { CardMenuComponent } from './components/card-menu/card-menu.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { FieldComponent } from './components/field/field.component';
import { FilterComponent } from './components/filter/filter.component';
import { AdvancedPieChartComponent } from './components/generic-charts/advanced-pie-chart/advanced-pie-chart.component';
import { FooterStackedChartComponent } from './components/generic-charts/footer-stacked-char/footer-stacked-chart.component';
import { NormalizedHorizontalBarChartComponent } from './components/generic-charts/normalized-horizontal-bar-chart/normalized-horizontal-bar-chart.component';
import { PieChartComponent } from './components/generic-charts/pie-chart/pie-chart.component';
import { GenericFormComponent } from './components/generics-crud-components/generic-form/generic-form.component';
import { CustomColumnsDirective } from './components/generics-crud-components/generic-list/directives/custom-columns.directive';
import { GenericListComponent } from './components/generics-crud-components/generic-list/generic-list.component';
import { LoadingCustomInterceptor } from './components/loading-custom/config/loading-custom-interceptor';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PaginatorCustomDinamicComponent } from './components/paginator-custom-dinamic/paginator-custom-dinamic.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { TableBodyDirective } from './components/table-custom/config/table-body.directive';
import { TableEmptyDirective } from './components/table-custom/config/table-empty.directive';
import { TableHeaderDirective } from './components/table-custom/config/table-header.directive';
import { TableCustomComponent } from './components/table-custom/table-custom.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UploadButtonCustomComponent } from './components/upload-button-custom/upload-button-custom.component';
import { UploadLargeFileComponent } from './components/upload-large-file/upload-large-file.component';
import { UploadOneFileComponent } from './components/upload-one-file/upload-one-file.component';
import { AlphanumericOnlyDirective } from './directives/alphanumeric-only.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { ListBoxComponent } from './components/list-box/list-box.component';
import { UploadOneFileV2Component } from './components/upload-one-file-v2/upload-one-file-v2.component';

const modules = [
  MultiSelectModule,
  CheckboxModule,
  RadioButtonModule,
  ChipsModule,
  TabViewModule,
  InputSwitchModule,
  AutoCompleteModule,
  DragDropModule,
  CalendarModule,
  BreadcrumbModule,
];

const components = [
  AlertCustomComponent,
  LoadingCustomComponent,
  PaginatorCustomComponent,
  TableCustomComponent,
  TableHeaderDirective,
  TableBodyDirective,
  TableEmptyDirective,
  UpperCaseDirective,
  OnlyNumberDirective,
  AlphanumericOnlyDirective,
  CardComponent,
  UploadOneFileComponent,
  PageTitleComponent,
  SearchInputComponent,
  FilterComponent,
  ToggleSwitchComponent,
  StatusPipe,
  AutocompleteComponent,
  FieldComponent,
  DragAndDropComponent,

  // Componentes Extendíveis Genericos
  GenericListComponent,
  GenericFormComponent,
  CustomColumnsDirective,
  PieChartComponent,
  AdvancedPieChartComponent,
  FooterStackedChartComponent,
  NormalizedHorizontalBarChartComponent,
  UploadLargeFileComponent,
  CardMenuComponent,
  DatePickerComponent,
  UploadButtonCustomComponent,
  PhoneMaskPipe,
  RadioButtonComponent,
  PaginatorCustomDinamicComponent,
  ListBoxComponent,
  UploadOneFileV2Component
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBorderRadius: '0px',
      fullScreenBackdrop: true,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      primaryColour: '#007bff',
      secondaryColour: '#0069d9',
      tertiaryColour: '#007bff',
    }),
    NgxPaginationModule,
    NgxChartsModule,
    ...modules,
  ],
  exports: [...modules, ...components],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingCustomInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
