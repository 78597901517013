import { AfterViewInit, Directive, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { AbstractControl, FormControlName, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';
import { fromEvent, merge, Observable } from 'rxjs';

import { Util } from 'src/app/shared/utils/util';
import { ButtonSubmit } from '../../core/models/button-submit.model';
import { ValidateMessage } from '../../core/models/validate-message.model';
import { GenericValidatorForm } from './generic-validator-form';
import { ValidationMessages } from './validation-messages';
import { ValidatorsMessage } from './validatorsMessage';

import { SubSink } from 'subsink';
import { ValidatorsCustom } from './validators-custom';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class FormBase implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef })
  public formInputElements: ElementRef[];

  public nameScreen = '';
  public pageId = '';
  public typePage = '';
  public sub = new SubSink();
  public order: boolean = false;
  public collumnName: string;

  // Utilizados para validação e submição
  public validateMessage = new ValidateMessage();
  public buttonSubmit = new ButtonSubmit();
  public form: FormGroup;
  public validatorsMessage = new ValidatorsMessage();
  private permissionUser;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public permissionsService?: NgxPermissionsService,
    public validateMessagesLocal?: any
  ) {
    this.getParamsScreen();
    if (!this.validateMessagesLocal) {
      this.validateMessagesLocal = {};
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.controlsBlurValidate();
    this.createValidateFields();
  }

  public setPermissionInComponent(
    permissions: string[],
    permissionUser: string,
    redirect = false,
    cadEdit = false
  ) {
    this.permissionUser = permissionUser;
    const existList = permissions.find((x) => x.includes(permissionUser));

    if (!existList && redirect) {
      return this.router.navigate(['/sem-permissao']);
    }

    this.permissionsService.loadPermissions(permissions);
  }

  public hiddenComponenteWithAll(permissions: string[]) {
    const existList = permissions.find((x) => x.includes(this.permissionUser));

    if (!existList) {
      return false;
    }

    return true;
  }

  /**
   * Função que obtem a ação a ser executada
   */
  public getParamsScreen() {
    this.pageId = this.activatedRoute.snapshot.params.id;
    this.nameScreen = Util.getScreenName(this.pageId);
  }

  /**
   * Função que realiza a validação por Blur
   */
  public controlsBlurValidate() {
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );
    merge(...controlBlurs).subscribe((value) => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(this.form);
    });
  }

  /**
   * Seta a classe de erro no campo
   * @param field Campo a ser realizado a tratativa
   */
  public setErrorValidate(field) {
    return Util.setErrorsValidate(
      this.form,
      this.validateMessage.messageDisplay,
      field
    );
  }

  /**
   * Função que habilita/desabilita o botão de salvar
   * verificando ser o form é valido
   */
  public enableShipping() {
   if (this.form.valid && !this.buttonSubmit.buttonSubmited) {
      return false;
    }
    return true;
  }

  /**
   * Utilizados no autocomplete
   */
  public updateErrors() {
    setTimeout(() => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(this.form);
    }, 100);
  }

  /**
   * Função que força o processamento das mensagens vindo do input child
   */
  forceProcessMessages(){
    this.validateMessage.messageDisplay = this.validateMessage.genericValidator.processMessages(this.form);
  }

  /**
   * Mensagens utilizadas na validação
   */
  public createValidateFields() {
    this.validateMessage.validationMessages = { ...ValidationMessages.msg(), ...this.validateMessagesLocal };
    this.validateMessage.genericValidator = new GenericValidatorForm(
      this.validateMessage.validationMessages
    );
  }

  public choosePageUpdateOrView(): void {
    this.typePage = this.activatedRoute.snapshot.paramMap.get('type');
    if (this.typePage === 'visualizar') {
      this.form.disable();
    }
  }

    /**
   * Função que retorna classe de validação a partir de um Form
   * @param form Form a ser validado
   */
     public getClassFormValid(form: AbstractControl)
     {
       if (form?.touched) {
        return form.valid ? '' : 'is-invalid' ;
       }
       return '';
     }

  get botaoViewFilter(): boolean {
    return this.router.url.includes('visualizar');
  }

  get formValue(): any {
    return this.form.getRawValue();
  }

  get isView() {
    return this.router.url.includes('visualizar');
  }

  get isEdit() {
    return this.router.url.includes('editar');
  }

  get pathBack() {
    const splitedPath = this.router.url.split('/');
    const indexAtRemove = this.pageId ? -2 : -1;
    return splitedPath.slice(0, indexAtRemove).join('/');
  }
  public isRequired(controlName: string, formGroup = this.form) {
    const control = formGroup.get(controlName);
    return control?.validator && control.validator({} as AbstractControl) &&  control.validator({} as AbstractControl)?.hasOwnProperty('required');
  }

  /**
   * Método que habilita ou desabilita os controles passados no array de controles.
   * @param controls Array de chaves do formulário para serem habilitadas ou desabilitadas.
   * @param enable Variável que recebe um booleano para habilitar (true) ou desabilitar (false) os controles passados.
   */
  public enableOrDisableControls(controls: string[], enable: boolean) {
    controls.forEach(controlKey => {
      if(!this.form.get(controlKey)[enable ? 'enabled' : 'disabled']){
        this.form.get(controlKey)[enable ? 'enable' : 'disable']();
      }
      if (this.isView) this.form.get(controlKey).disable();
    });
  }

  /**
 * Adiciona ou remove validadores personalizados aos controles de um formulário com base nas configurações fornecidas.
 * @param controls Um array de chaves que representam os controles do formulário aos quais os validadores serão aplicados.
 * @param add Um booleano que indica se os validadores devem ser adicionados (true) ou removidos (false).
 */
  addRemoveRequiredValidators(controls: string[], add: boolean) {
    controls.forEach(controlName => {
      const control = this.form.get(controlName);

      if (control) {
        const isArray = Array.isArray(control.value);
        const validatorsRequired = isArray ? [ValidatorsCustom.noWhitespaceValidator, ValidatorsCustom.arrayLength] : [ValidatorsCustom.noWhitespaceValidator];

        add ? control.setValidators(validatorsRequired) : control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }

  /**
   * Método que reseta os controles passados no array de controles.
   * @param controls Array de chaves do formulário que deseja limpar.
   */
  public clearControls(controls: string[]) {
    controls.forEach(controlKey => {
      if (this.form.get(controlKey)?.value) {
        this.form.get(controlKey)?.reset();
      }
      if (this.validateMessage.genericValidator) {
        this.forceProcessMessages();
      }
    })
  }

  /**
   * Método que simula o touched no formControl alvo e atualiza os erros..
   * @param formControlName Nome do formControl que deseja executar o markAsTrouched e atualizar os erros.
   */
  markAsTouchedAndUpdate(formControlName: string){
    this.form.get(formControlName).markAsTouched();
    this.updateErrors();
  }  

}
